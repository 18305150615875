import { action, observable } from "mobx";

class mainStore {
  @observable articles = null;
  @observable searchValue = null;
  @action setValuesAfterSearch = ({ articles, searchValue }) => {
    this.articles = articles;
    this.searchValue = searchValue;
  }

}

export default new mainStore();